import {
  TideItemForDestinations,
  TideItemForGeographicalRegion,
  TideItemForNavigationSection,
  TideItemForNewsletterForm,
  TideItemForNotificationComponentConnection,
  TideItemForPage,
  TideItemForSearchPage,
  TideItemForUspGroup,
  TideItemForWebsite,
} from "../types";
import { compact, first, isEmpty } from "lodash";
import { generatePath, getChildNavigationLinks } from "../utils";
import Breadcrumb from "../components/breadcrumb";
import DestinationsGrid from "../components/destination-grid";
import Footer from "../components/footer";
import Hero from "../components/hero";
import Layout from "../components/layout";
import { LookupQueryData } from "../components/qsm";
import Navbar from "../components/navbar";
import React from "react";
import { graphql } from "gatsby";
import StickyBar from "../components/sticky-bar";
import Link from "../components/link";
import Icon from "../components/icon";
import Seo from "../components/seo";
import NotificationBar from "../components/notification-bar";
import { AlternateRoute } from "../../.gatsby/gatsby-node";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";

interface DestinationsPageTemplateProps {
  data: DestinationsPageData;
  pageContext: {
    alternateRoutes: AlternateRoute[];
  };
}

const DestinationsPageTemplate: React.FC<DestinationsPageTemplateProps> = ({
  data,
  pageContext,
}) => {
  const notificationBar = first(data.notifications?.nodes);
  const { t } = useI18next();
  return (
    <Layout>
      <Seo
        title={data.destinationsPage.content?.general?.title ?? ""}
        seoTitle={data.destinationsPage.content?.seo?.seoTitle ?? undefined}
        seoDescription={data.destinationsPage.content?.seo?.seoDescription ?? undefined}
        seoKeywords={data.destinationsPage.content?.seo?.seoKeywords ?? undefined}
      />
      {!isEmpty(notificationBar?.content?.general?.notification) && (
        <NotificationBar
          text={notificationBar?.content?.general?.notification ?? ""}
          icon={notificationBar?.content?.general?.iconFontAwesome ?? "fas fa-info"}
          backgroundColor={notificationBar?.content?.general?.backgroundColor ?? "#e74c3c"}
        />
      )}
      <Navbar
        alternateRoutes={pageContext.alternateRoutes}
        mainNavigationSection={data.mainNavigationSection}
        fullscreenPrimaryNavigationSection={data.fullscreenPrimaryNavigationSection}
        fullscreenSecondaryNavigationSection={data.fullscreenSecondaryNavigationSection}
        socialNavigationSection={data.socialNavigationSection}
        legalNavigationSection={data.legalNavigationSection}
        phone={data.website?.content?.contact?.phone ?? ""}
        destinations={data.navigationDestinations}
      />
      <StickyBar
        navigationLinks={getChildNavigationLinks(data.stickyNavigationSection)}
        actionsComponent={
          data.searchPage && (
            <Link
              href={`/${data.searchPage.content?.general?.slug}`}
              internal
              title={t("SEARCH_AND_BOOK")}
              className="cta cta--icon"
            >
              <Icon name="ui-search" />
              <Trans>SEARCH_AND_BOOK</Trans>
            </Link>
          )
        }
      />
      <Hero
        type="compact"
        title={data.destinationsPage?.content?.general?.title ?? undefined}
        imageSrc={data.destinationsPage?.content?.general?.headerImage?.url ?? undefined}
      />
      <Breadcrumb
        items={compact([
          { id: "home", title: "Home", url: "/" },
          {
            id: "destinations",
            title: data.destinationsPage.content?.general?.title ?? "",
            url: generatePath(data.destinationsPage),
          },
        ])}
      />
      <DestinationsGrid
        contactPath={data.contactPage.content?.general?.slug ?? undefined}
        agenciesPath={data.agenciesPage.content?.general?.slug ?? undefined}
        title={data.destinationsPage.content?.general?.introductionTitle ?? undefined}
        introduction={data.destinationsPage.content?.general?.introduction ?? undefined}
        geographicalRegions={
          data.destinationsPage.childItems?.filter(
            (tideItem) => tideItem?.templateName === "Geographical region"
          ) as TideItemForGeographicalRegion[]
        }
      />
      <Footer
        footerNavigationSection={data.footerNavigationSection}
        socialNavigationSection={data.socialNavigationSection}
        legalNavigationSection={data.legalNavigationSection}
        address={data.website?.content?.contact?.address ?? ""}
        phone={data.website?.content?.contact?.phone ?? ""}
        email={data.website?.content?.contact?.email ?? ""}
        uspGroup={data.footerUspGroup}
        newsletterForm={data.newsletterForm}
      />
    </Layout>
  );
};

interface DestinationsPageData extends LookupQueryData {
  searchPage: TideItemForSearchPage;
  destinationsPage: TideItemForDestinations;
  website: TideItemForWebsite;
  notifications: TideItemForNotificationComponentConnection;
  navigationDestinations: TideItemForDestinations;
  mainNavigationSection: TideItemForNavigationSection;
  fullscreenPrimaryNavigationSection: TideItemForNavigationSection;
  fullscreenSecondaryNavigationSection: TideItemForNavigationSection;
  legalNavigationSection: TideItemForNavigationSection;
  stickyNavigationSection: TideItemForNavigationSection;
  socialNavigationSection: TideItemForNavigationSection;
  contactNavigationSection: TideItemForNavigationSection;
  footerNavigationSection: TideItemForNavigationSection;
  footerUspGroup: TideItemForUspGroup;
  newsletterForm: TideItemForNewsletterForm;
  agenciesPage: TideItemForPage;
  contactPage: TideItemForPage;
}

export const query = graphql`
  query DestinationsPageQuery($id: String, $language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    searchPage: tideItemForSearchPage(language: { eq: $language }) {
      id
      content {
        general {
          slug
        }
      }
    }
    agenciesPage: tideItemForPage(name: { eq: "Reisbureaus" }, language: { eq: $language }) {
      id
      content {
        general {
          slug
        }
      }
    }
    contactPage: tideItemForPage(name: { eq: "Contact" }, language: { eq: $language }) {
      id
      content {
        general {
          slug
        }
      }
    }
    destinationsPage: tideItemForDestinations(id: { eq: $id }) {
      name
      content {
        general {
          title
          introductionTitle
          introduction
          headerImage {
            url
          }
          slug
        }
        seo {
          seoTitle
          seoDescription
          seoKeywords
        }
      }
      childItems {
        ... on TideItemForGeographicalRegion {
          id
          name
          templateName
          content {
            general {
              title
            }
          }
          childItems {
            ... on TideItemForCountry {
              id
              name
              templateName
              content {
                general {
                  title
                  thumbnailPicture {
                    url
                  }
                }
              }
              ...countryOrRegionPathFragment
            }
            ... on TideItemForRegion {
              id
              name
              templateName
              content {
                general {
                  title
                  thumbnailPicture {
                    url
                  }
                }
              }
              ...countryOrRegionPathFragment
            }
          }
        }
      }
    }
    website: tideItemForWebsite(name: { eq: "Travelworld" }, language: { eq: $language }) {
      content {
        contact {
          phone
          email
          address
        }
      }
    }
    ...navigationDestinationsFragment
    notifications: allTideItemForNotificationComponent(filter: { language: { eq: $language } }) {
      nodes {
        content {
          general {
            notification
            iconFontAwesome
            backgroundColor
          }
        }
      }
    }
    mainNavigationSection: tideItemForNavigationSection(
      name: { eq: "Main Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    fullscreenPrimaryNavigationSection: tideItemForNavigationSection(
      name: { eq: "Fullscreen Primary" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    fullscreenSecondaryNavigationSection: tideItemForNavigationSection(
      name: { eq: "Fullscreen Secondary" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    socialNavigationSection: tideItemForNavigationSection(
      name: { eq: "Social Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    contactNavigationSection: tideItemForNavigationSection(
      name: { eq: "Contact Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    legalNavigationSection: tideItemForNavigationSection(
      name: { eq: "Legal Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    stickyNavigationSection: tideItemForNavigationSection(
      name: { eq: "Sticky Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    footerNavigationSection: tideItemForNavigationSection(
      name: { eq: "Footer Navigation" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    footerUspGroup: tideItemForUspGroup(name: { eq: "Footer Usps" }, language: { eq: $language }) {
      ...uspGroupFields
    }
    newsletterForm: tideItemForNewsletterForm(
      name: { eq: "Newsletter" }
      language: { eq: $language }
    ) {
      content {
        externalMail {
          templateConfirmationMail {
            tideId
          }
        }
        contactDetails {
          tag {
            tideId
          }
        }
        form {
          privacyPage
        }
      }
    }
  }
`;

export default DestinationsPageTemplate;
