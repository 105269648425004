import React, { useEffect, useState } from "react";
import { kebabCase, map } from "lodash";
import { TideItemForCountry, TideItemForGeographicalRegion, TideItemForRegion } from "../../types";
import { buildClassName, generateCountryOrRegionPath } from "../../utils";
import Expert from "../expert";
import Icon from "../icon";
import Link from "../link";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";

interface DestinationsGridProps {
  title?: string;
  introduction?: string;
  geographicalRegions: TideItemForGeographicalRegion[];
  agenciesPath?: string;
  contactPath?: string;
}

const DestinationsGrid: React.FC<DestinationsGridProps> = ({
  title,
  introduction,
  geographicalRegions,
  agenciesPath,
  contactPath,
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const { t } = useI18next();
  const handleScroll: EventListener = () => {
    const sections = document.querySelectorAll(".grid__section");
    const index = map(sections, (element) => element.getBoundingClientRect().top).findIndex(
      (top) => top > 0
    );
    setCurrentIndex(index === -1 ? 0 : index);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  });

  return (
    <section className="section">
      <div className="grid-sidebar">
        <section className="section">
          <div className="single-column">
            <div className="single-column__container">
              <div className="single-column__title">
                <h2>{title}</h2>
              </div>
              <div
                className="single-column__column"
                dangerouslySetInnerHTML={{
                  __html: introduction ?? "",
                }}
              ></div>
            </div>
          </div>
        </section>
        <div className="grid-sidebar__products">
          <div className="grid-sidebar__grid">
            <div className="productcard-grid productcard-grid--destinations">
              <div className="grid">
                {geographicalRegions.map((geographicalRegion) => {
                  const countryOrRegionItems = (geographicalRegion.childItems as (
                    | TideItemForCountry
                    | TideItemForRegion
                  )[]).filter(
                    (childTideItem) =>
                      childTideItem?.templateName === "Country" ||
                      childTideItem?.templateName === "Region"
                  );
                  return countryOrRegionItems.length > 0 ? (
                    <div
                      className="grid__section"
                      key={geographicalRegion.id}
                      id={kebabCase(geographicalRegion.name)}
                    >
                      <h3 className="grid__section-heading">
                        {geographicalRegion.content?.general?.title}
                      </h3>
                      <div className="grid__items">
                        {countryOrRegionItems.map((countryOrRegion) => {
                          return (
                            <div className="grid__item" key={countryOrRegion.id}>
                              <Link
                                internal
                                title={t("COUNTRY")}
                                className="grid__item-link"
                                href={generateCountryOrRegionPath(countryOrRegion)}
                              >
                                <div className="image">
                                  <img
                                    src={
                                      countryOrRegion.content?.general?.thumbnailPicture?.url ??
                                      undefined
                                    }
                                    alt={countryOrRegion.content?.general?.title ?? undefined}
                                    className="image__content"
                                  />
                                </div>
                                <div className="grid__item-copy">
                                  <div className="destination-label">
                                    <Icon name="ui-marker" />
                                    {countryOrRegion.content?.general?.title}
                                  </div>
                                </div>
                              </Link>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : null;
                })}
              </div>
            </div>
          </div>
          <aside className="grid-sidebar__sidebar">
            <div className="schedule">
              <div className="schedule__anchors">
                {geographicalRegions.map((geographicalRegion, index) => {
                  const countryOrRegionCount =
                    geographicalRegion.childItems?.filter(
                      (childTideItem) =>
                        childTideItem?.templateName === "Country" ||
                        childTideItem?.templateName === "Region"
                    ).length ?? 0;
                  const anchor = kebabCase(geographicalRegion.name);
                  return countryOrRegionCount > 0 ? (
                    <a
                      key={geographicalRegion.id}
                      onClick={(e) => {
                        e.preventDefault;
                        document.getElementById(anchor)?.scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                      title={geographicalRegion.content?.general?.title ?? undefined}
                      className={buildClassName([
                        "schedule__anchor",
                        index === currentIndex && "schedule__anchor--active",
                      ])}
                    >
                      <p className="schedule__anchor-copy">
                        <span className="schedule__anchor-heading">
                          {geographicalRegion.content?.general?.title}
                        </span>
                        <span className="schedule__anchor-amount">{countryOrRegionCount}</span>
                      </p>
                    </a>
                  ) : null;
                })}
              </div>
              <Expert
                title={t("WE_LOVE_TO_HELP_YOU")}
                description={
                  <p>
                    <Trans i18nKey="SEND_US_A_MESSAGE_OR_FIND_AGENT">
                      Stuur ons{" "}
                      <Link
                        href={`/${contactPath}`}
                        internal
                        title={t("SEND_US_A_MESSAGE")}
                        className="link"
                      >
                        een berichtje
                      </Link>
                      , of{" "}
                      <Link
                        href={`/${agenciesPath}`}
                        internal
                        title={t("FIND_YOUR_AGENT")}
                        className="link"
                      >
                        vind je reisexpert in de buurt
                      </Link>
                      .
                    </Trans>
                  </p>
                }
              >
                <div className="social-items">
                  <a href="#link" title={t("CONTACT_US_ON_MESSENGER")} className="social-item">
                    <Icon name="social-messenger" />
                  </a>
                  <a href="#link" title={t("CONTACT_US_ON_MESSENGER")} className="social-item">
                    <Icon name="social-whatsapp" />
                  </a>
                  <a href="#link" title={t("CONTACT_US_ON_MESSENGER")} className="social-item">
                    <Icon name="social-phone" />
                  </a>
                  <a href="#link" title={t("CONTACT_US_ON_MESSENGER")} className="social-item">
                    <Icon name="social-email" />
                  </a>
                </div>
              </Expert>
            </div>
          </aside>
        </div>
      </div>
    </section>
  );
};

export default DestinationsGrid;
